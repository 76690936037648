<template>
  <el-dialog v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="480px">
    <div class="mb-20 content">{{state.body}}</div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="dialogVisible = false,emit('delEmit',state.id)"></oabutton>
        <oabutton class="searcML" width='120' height='40' CSStype=3 title="取消" @buttonclick="dialogVisible = false"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['delEmit'])
const state = reactive({
  body:'',
  id:{},//传入需要删除的id
})
let show = ((title='移除员工',body='确定移除该成员吗？',id={}) => {
  state.body=body
  state.id=id
  dialogloading.value = false
  dialogVisibletitle.value = title
  dialogVisible.value = true
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
.content {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.88);
}
</style>