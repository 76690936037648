<template>
  <div class="box pt-r" v-loading="state.loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="input_box w-360 m-0-a mt-20 mb-20">
      <el-input class="input search_input" placeholder="请输入搜索关键字" v-model="filterText" :maxlength="config.inputMaxlength"
        show-word-limit>
        <template #append>
          <img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="seachDeptData">
        </template>
      </el-input>
    </div>
    <el-tree ref="treeRef" :data="state.deptData" node-key="id" :expand-on-click-node="false" :props="state.defaultProps"
      :filter-node-method="filterNode" :highlight-current="true" @node-click="nodeClick" :default-expand-all="true">
      <!--  :current-node-key="state.nowDept.id" -->
      <template #default="{ node, data }">
        <div class="w100 h100 dp-f jc-sb ai-c">
          <div class="dp-f">
            <img src="@/assets/img/role/roleIcon.png" class="w-18 h-18 mr-12 fs-0">
            <div>{{ node.label }} ({{ data.departmentsNumber }})</div>
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">
              <img src="@/assets/img/general/extend.png" v-if="data.parentId!=0">
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div v-permission="'role_dept:edit'">
                  <el-dropdown-item
                    @click="unref(operationRef).editclick(data)">编辑名称</el-dropdown-item>
                </div>
                <div  v-permission="'role_dept:del'">
                  <el-dropdown-item
                    @click="unref(delconfirmRef).show('删除部门', '确认已成该部门？', data.id)">删除</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </template>
    </el-tree>
    <!-- <div class="addIcon cu-p" @click="unref(operationRef).add()" v-permission="'role_dept:add'">
      <img src="@/assets/img/role/sidebarBottomIcon.png">
    </div> -->
  </div>
  <!-- 单用户操作弹出框 -->
  <operation ref="operationRef" @Emit="getnewDeptData"></operation>
  <!-- 删除确认框 -->
  <delconfirm ref="delconfirmRef" @delEmit="delDept"></delconfirm>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, nextTick, getCurrentInstance } from "vue";
import { httpToken } from "@/utils/request";
import { getmenuData, getdeptData } from "@/utils/server/selectdata";
import qs from "qs";
import operation from "./dept/operation.vue";//部门操作框
import delconfirm from "../../../../components/dialog/delconfirm.vue";
const emit = defineEmits(['DeptChange', 'getnewDeptData'])
const instance = getCurrentInstance();
const operationRef = ref();//单用户操作弹出框ref
const delconfirmRef = ref()// 删除确认框ref
const treeRef = ref() //树型组件ref
const filterText = ref('')
const state = reactive({
  loading: false,
  nowDept: {},//当前选中部门数据
  deptData: [],
  defaultProps: {
    children: 'children',
    label: 'label',
  }
})
// 节点点击方法
const nodeClick = (data) => {
  setnowDept(data)
}
// 过滤部门数据
const seachDeptData = () => {
  unref(treeRef.value).filter(filterText.value)
}
// 过滤函数
const filterNode = (value, data) => {
  if (!value) return true
  return data.label.includes(value)
}
// 设置当前选中部门
const setnowDept = (data) => {
  state.nowDept = data
  emit('DeptChange', data)
}
// 删除部门
const delDept = (id) => {
  httpToken({
    method: "post",
    url: '/admin/sys-dept/del',
    data: qs.stringify({
      ids: id
    })
  }).then(() => {
    getnewDeptData()
  })
}
// type 1默认 2单更新部门数据
const getnewDeptData = (type = 1) => {
  if (type == 1) {
    state.loading = true;
  }
  getdeptData().then((res) => {
    state.deptData = res
    emit('getnewDeptData', res)
    if (type == 1) {
      if (state.deptData.length > 0) {
        setnowDept(state.deptData[0])
        // 默认高亮第一条
        nextTick(() => {
          unref(treeRef.value).setCurrentKey(state.deptData[0].id)
        })
      }
    }
    instance.proxy.$forceUpdate();
    if (type == 2) {
      // 还原高亮
      nextTick(() => {
        unref(treeRef.value).setCurrentKey(state.nowDept.id)
      })
    }
    state.loading = false;
  }).catch(() => {
    state.loading = false;
  })
}
const newDept=()=>{
  unref(operationRef).add()
}
getnewDeptData()
defineExpose({
  getnewDeptData,newDept
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置

.box {
  height: 100%;
  overflow: auto;
}

::v-deep .el-tree-node__content {
  height: 54px;
}

.addIcon {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
</style>